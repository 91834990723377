import { Invoice } from 'components/ExportPDFModal/types';

export interface PostFormFields {
    [key: string]: any;
    itensPorPagina: string;
    emitente: string;
    cliente: string;
    numeroNF: string[];
    dataEmissaoNFe: {
        periodoDe: string | null;
        periodoAte: string | null;
    };
    dataBaixaCanhoto: {
        periodoDe: string | null;
        periodoAte: string | null;
    };
    modalidadeFrete: {
        cif: boolean;
        fob: boolean;
    };
    statusCanhoto: string;
    chaveNFe: string[];
    serie: string;
    produto: string;
    volume: {
        rangeDe: string;
        rangeAte: string;
    };
    numeroPedido: string;
    nomeTransportador: string;
    CNPJTransportador: string;
    codigoTransportador: string;
}

export interface IGetImageS3Props {
    data: ImageS3Props[];
}

export interface ImageS3Props {
    id: string;
    signedUrl: string;
    base64: string;
}

export interface RevertInvoiceStatusParams {
    initialDate: string;
    finalDate: string;
    transporter: string;
}

export interface VerifyHashParams {
    numeroNF: string;
    hash: string;
}

export enum StatusHash {
    SUCCESS = 'Sucesso',
    ERROR = 'Erro',
}

export interface InvoiceHashed extends Invoice {
    assinatura?: {
        id: string;
        base64: string;
        signedUrl: string;
    };
}

export interface VerifyHashResponse {
    message?: string;
    data: InvoiceHashed;
    status: StatusHash;
}
