import { AuthContext } from 'contexts/auth/context';
import React, { useContext } from 'react';

import { Navigate, Outlet } from 'react-router-dom';

type PermissionLevels = 1 | 2 | 3;
interface ProtectedRoutesProps {
    levelAccess?: PermissionLevels[] | PermissionLevels;
    children?: React.ReactNode;
}

const ProtectedRoutes = ({ levelAccess, children }: ProtectedRoutesProps) => {
    const localStorageToken = localStorage.getItem('token');
    const { accessLevel } = useContext(AuthContext);
    const isDev = accessLevel === 3;
    const childReturn = children ? <>{children}</> : <Outlet />;

    if (!localStorageToken) {
        return <Navigate to="/login" replace />;
    }

    if (isDev) return childReturn;

    if (Array.isArray(levelAccess) && !levelAccess.includes(accessLevel as 1 | 2))
        return <Navigate to="/inicio" replace />;

    if (typeof levelAccess === 'number' && levelAccess !== accessLevel)
        return <Navigate to="/inicio" replace />;

    return childReturn;
};

export default ProtectedRoutes;
